import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { TeamMember } from "../lib/types";
import IconButton from "./iconbutton";

export interface TeamCardProps {
  member: TeamMember;
}

const TeamCard: React.FC<TeamCardProps> = ({ member }) => (
  <div className="flex flex-col items-center shadow-center rounded-md space-y-2 pb-2">
    {member.image.localFile.childImageSharp ? (
      <GatsbyImage
        image={getImage(member.image.localFile)}
        alt={`Photo de ${member.name}`}
        className="rounded-t-md w-full"
      />
    ) : (
      <img
        alt={`Photo de ${member.name}`}
        src={member.image.localFile.publicURL}
        className="rounded-t-md w-full"
      />
    )}
    <div className="font-bold uppercase text-base px-2 lg:text-lg">
      {member.name}
    </div>
    <div className="capitalize text-sm text-center px-2 lg:text-base">
      {member.role}
    </div>

    <div className="flex space-x-2">
      <IconButton
        external
        type="light"
        icon={"linkedin"}
        href={member.linkedinProfile}
      />
      {member.twitterHandle && (
        <IconButton
          external
          type="light"
          icon={"twitter"}
          href={`https://twitter.com/${member.twitterHandle}`}
        />
      )}
    </div>
  </div>
);

export default TeamCard;
