import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";

// components
import CustomerReviewCard from "../components/customer-review-card";
import HeadTitle from "../components/headtitle";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ShareHolderCard from "../components/shareholdercard";
import CategoryHeader from "../components/categoryheader";
import TeamSlider from "../components/teamslider";
import ResponsiveSlider from "../components/responsive-slider";

// images
import imagePresse from "../images/about-press.jpg";
import imageVideo from "../images/about-videos.jpg";

// types & interfaces
import type {
  CustomerReview,
  GatsbyImageType,
  SEO,
  ShareHolder,
  TeamMember,
  PageProps,
} from "../lib/types";

type AboutPageProps = {
  title: string;
  sectionOtherTitle: string;
  SEO: SEO;
  description: string;
  cover: { localFile: GatsbyImageType };

  teamSection: {
    title: string;
    members: Array<TeamMember>;
  };

  customerReviewSection: {
    title: string;
    youtubeFeatured: string;
    reviews: Array<CustomerReview>;
  };

  shareholderSection: {
    title: string;
    shareholders: Array<ShareHolder>;
  };

  ecosystemSection: {
    title: string;
    ecosystems: Array<{
      description: string;
      sector: string;
      logos: Array<{ localFile: GatsbyImageType }>;
    }>;
  };

  missionSection: {
    title: string;
    mission: string;
    illustration: { localFile: GatsbyImageType };
  };

  valeurSection: {
    title: string;
    valeurs: string;
    illustration: { localFile: GatsbyImageType };
  };
};

const AboutPage: React.FC<PageProps<AboutPageProps>> = ({
  data: {
    page: {
      title,
      SEO,
      cover,
      description,
      teamSection,
      shareholderSection,
      missionSection,
      valeurSection,
      ecosystemSection,
      customerReviewSection,
      sectionOtherTitle,
    },
  },
  location: { pathname },
}) => (
  <>
    <Layout active={"#about"}>
      <Seo {...SEO} pathname={pathname} />
      <main className="mt-20 space-y-15 overflow-x-hidden">
        <Header title={title} cover={cover} />
        <Description description={description} />
        <Team teamSection={teamSection} />
        <Mission
          missionSection={missionSection}
          valeurSection={valeurSection}
        />
        <ShareHolders shareholderSection={shareholderSection} />
        <Ecosystems ecosystemSection={ecosystemSection} />
        <CustomerReviews customerReviewSection={customerReviewSection} />
        <LinksSection sectionOtherTitle={sectionOtherTitle} />
      </main>
    </Layout>
  </>
);

export default AboutPage;

const Header: React.FC<{
  title: string;
  cover: { localFile: GatsbyImageType };
}> = ({ title, cover }) => (
  <section>
    <CategoryHeader title={title} cover={cover} />
  </section>
);

const Description: React.FC<{ description: string }> = ({ description }) => (
  <section className="px-5 md:mt-15 xl:mt-20 md:px-10 xl:px-15">
    <article
      className="article__MEC"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </section>
);

const Team: React.FC<Pick<AboutPageProps, "teamSection">> = ({
  teamSection: { title, members },
}) => (
  <section className="px-5 space-y-4 md:px-10 lg:px-15">
    <HeadTitle
      label={title}
      as="h2"
      className="uppercase italic text-lg lg:text-xl"
    />
    <TeamSlider members={members} className="" />
  </section>
);

const Mission: React.FC<
  Pick<AboutPageProps, "missionSection" | "valeurSection">
> = ({ missionSection, valeurSection }) => {
  // @ts-ignore
  return (
    <section className="px-5 space-y-20 md:px-10 lg:px-15">
      <div className="grid gap-4 lg:grid-cols-5 xl:gap-10">
        <div className="col-span-3">
          <HeadTitle
            label={missionSection.title}
            as="h2"
            className="uppercase italic text-lg lg:text-xl lg:col-span-5"
          />
          <article
            className="article__MEC "
            dangerouslySetInnerHTML={{ __html: missionSection.mission }}
          />
        </div>

        <GatsbyImage
          alt="Illustration de nos missions"
          image={getImage(missionSection.illustration.localFile)}
          className="!hidden object-contain col-span-2 lg:!block"
        />
      </div>

      <div className="grid gap-4 lg:grid-cols-5 xl:gap-10">
        <GatsbyImage
          alt="L'équipe monemprunt en plein travail"
          image={getImage(valeurSection.illustration.localFile)}
          className="!hidden object-contain col-span-2 lg:!block "
        />

        <div className="col-span-3">
          <HeadTitle
            label={valeurSection.title}
            as="h2"
            className="uppercase italic text-lg lg:text-xl"
          />
          <article
            className="article__MEC"
            dangerouslySetInnerHTML={{ __html: valeurSection.valeurs }}
          />
        </div>
      </div>
    </section>
  );
};

const ShareHolders: React.FC<Pick<AboutPageProps, "shareholderSection">> = ({
  shareholderSection: { shareholders: holders, title },
}) => (
  <section className="px-5 space-y-8 md:px-10 lg:px-15">
    <HeadTitle
      label={title}
      as="h2"
      className="uppercase italic text-lg lg:text-xl"
    />
    <ResponsiveSlider
      className="lg:gap-8"
      duration={5000}
      numItemsOnLaptop={3}
      numItemsOnPC={3}
      numItemsOnTablet={2}
      numItemsOnSmallTablet={2}
    >
      {holders.map((shareholder, index) => (
        <ShareHolderCard shareholder={shareholder} key={index} />
      ))}
    </ResponsiveSlider>
  </section>
);

const Ecosystems: React.FC<Pick<AboutPageProps, "ecosystemSection">> = ({
  ecosystemSection: { ecosystems, title },
}) => (
  <section className="px-5 space-y-8 md:px-10 lg:px-15">
    <HeadTitle
      label={title}
      as="h2"
      className="uppercase italic text-lg lg:text-xl"
    />

    <div className="grid gap-10 md:gap-12 md:grid-cols-2">
      {ecosystems.map((ecosystem, index) => (
        <div className="grid gap-2 lg:grid-cols-3" key={index}>
          <div className="col-span-2">
            <span className="font-bold">{ecosystem.sector} : </span>
            <article
              dangerouslySetInnerHTML={{ __html: ecosystem.description }}
              className={"article__MEC"}
            />
          </div>

          <div className="flex justify-between lg:flex-col lg:space-y-4">
            {ecosystem.logos.map((logo, index) => (
              <React.Fragment key={index}>
                {logo.localFile?.childImageSharp ? (
                  <GatsbyImage
                    image={getImage(logo.localFile.childImageSharp)}
                    alt={`logos ${ecosystem.sector}`}
                    className="h-10 object-contain lg:h-12"
                  />
                ) : (
                  <img
                    src={logo.localFile?.publicURL}
                    alt={`logos ${ecosystem.sector}`}
                    className="h-10 object-contain lg:h-12"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  </section>
);

const CustomerReviews: React.FC<Pick<AboutPageProps, "customerReviewSection">> =
  ({ customerReviewSection: { youtubeFeatured, reviews, title } }) => (
    <section className="px-5 space-y-8 md:px-10 lg:px-15 text-center">
      <HeadTitle
        label={title}
        as="h2"
        className="uppercase italic text-lg lg:text-xl"
      />

      <div className="flex flex-col items-center space-y-10">
        <div className="w-full flex items-center justify-center">
          <iframe
            className="w-full h-44 max-w-xl md:h-64 lg:h-72"
            src={youtubeFeatured}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <ResponsiveSlider
          className={"gap-10"}
          numItemsOnTablet={2}
          numItemsOnPC={3}
          numItemsOnLaptop={4}
        >
          {reviews.map(review => (
            <CustomerReviewCard key={review.name} review={review} />
          ))}
        </ResponsiveSlider>
      </div>
    </section>
  );

const LinksSection: React.FC<Pick<AboutPageProps, "sectionOtherTitle">> = ({
  sectionOtherTitle: title,
}) => (
  <section className="px-5 pb-40 space-y-8 md:px-10 md:pb-52 lg:px-15 lg:pb-52">
    <HeadTitle
      label={title}
      as="h2"
      className="uppercase italic text-lg lg:text-xl"
    />
    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
      <div>
        <Link to="/pages/actualites">
          <img src={imagePresse} alt="Notre section presse" />
        </Link>
      </div>

      <div>
        <a
          href="https://www.youtube.com/channel/UChQ7LMHG4e-cNhz8-sQ1yQQ"
          target="_blank"
        >
          <img src={imageVideo} alt="Nos vidéos" />
        </a>
      </div>

      <div className="w-full md:col-span-2 lg:col-span-1">
        <a
          className="twitter-timeline"
          data-lang="fr"
          data-height="500"
          data-theme="light"
          href="https://twitter.com/monemprunt?ref_src=twsrc%5Etfw"
        >
          Tweets de @monemprunt
        </a>
      </div>
    </div>

    {/* Twitter script */}
    <Helmet>
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      />
    </Helmet>
  </section>
);

export const query = graphql`
  query pageApropos {
    page: strapiAPropos {
      id: strapiId
      title
      description
      sectionOtherTitle

      cover {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      SEO {
        description
        slug
        title
      }

      missionSection {
        title
        mission
        illustration {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
      valeurSection {
        title
        valeurs
        illustration {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }

      ecosystemSection {
        title
        ecosystems {
          description
          sector
          logos {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      shareholderSection {
        title
        shareholders {
          name
          description
          link
          logo {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      teamSection {
        title
        members {
          name
          role: function
          twitterHandle
          linkedinProfile
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      customerReviewSection {
        title
        youtubeFeatured
        reviews: customerReview {
          name
          review
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`;
