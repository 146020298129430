import * as React from "react";
import { useEffect, useRef } from "react";
import { useMediaQuery, usePagination } from "../lib/hooks";
import { AnimatePresence, motion } from "framer-motion";

export interface ResponsiveSliderProps {
  children: Array<React.ReactChild>;
  className?: string;
  numItemsOnPhone?: number;
  numItemsOnTablet?: number;
  numItemsOnSmallTablet?: number;
  numItemsOnPC?: number;
  numItemsOnLaptop?: number;
  duration?: number;
}

const ResponsiveSlider: React.FC<ResponsiveSliderProps> = ({
  children,
  numItemsOnLaptop = 5,
  numItemsOnPhone = 1,
  numItemsOnTablet = 3,
  numItemsOnSmallTablet = 2,
  numItemsOnPC = 4,
  className = "",
  duration = 3000,
}) => {
  const constraintsRef = useRef(null);
  const isTablet = useMediaQuery("(min-width: 768px)");
  const isSmallPhone = useMediaQuery("(min-width: 640px)");
  const isPC = useMediaQuery("(min-width: 1024px)");
  const isLaptop = useMediaQuery("(min-width: 1280px)");

  const itemsPerPage = isLaptop
    ? numItemsOnLaptop
    : isPC
    ? numItemsOnPC
    : isTablet
    ? numItemsOnTablet
    : isSmallPhone
    ? numItemsOnSmallTablet
    : numItemsOnPhone;

  const { currentPage, next, currentData, jump, maxPage } = usePagination({
    data: children,
    perPage: itemsPerPage,
    wrap: true,
  });

  useEffect(() => {
    // each 3 seconds change page
    const timeout = setInterval(next, duration);

    return () => {
      jump(0);
      clearInterval(timeout);
    };
  }, [children, itemsPerPage]);

  return (
    <AnimatePresence>
      <div ref={constraintsRef}>
        <AnimatedSlider
          page={currentPage}
          onDrag={next}
          parentRef={constraintsRef}
          className={`${className} grid gap-4 w-full`}
          style={{
            gridTemplateColumns: `repeat(${itemsPerPage}, minmax(0, 1fr))`,
          }}
        >
          {currentData()}
        </AnimatedSlider>
        {maxPage > 1 && (
          <div className={`w-full flex space-x-4 mt-4 justify-center`}>
            {Array(maxPage)
              .fill(1)
              .map((_, index) => (
                <button
                  aria-label={`Aller à la page ${index + 1}`}
                  key={index}
                  onClick={() => jump(index + 1)}
                  className={`rounded-full h-3 w-3 ${
                    index + 1 === currentPage
                      ? "bg-secondary-50"
                      : "bg-light-100"
                  }`}
                />
              ))}
          </div>
        )}
      </div>
    </AnimatePresence>
  );
};

export default ResponsiveSlider;

interface AnimatedSliderProps {
  page: number;
  className: string;
  parentRef: any;
  onDrag?: () => void;
  style?: React.CSSProperties;
}

const AnimatedSlider: React.FC<AnimatedSliderProps> = ({
  children,
  parentRef,
  onDrag,
  page,
  className,
  style,
}) => {
  // For animation
  let animationVariants = {
    enter: { x: 100, opacity: 0 },
    center: { x: 0, opacity: 1 },
    exit: { x: -100, opacity: 0 },
  };

  return (
    <motion.ul
      key={page}
      style={style}
      variants={animationVariants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
      drag="x"
      dragConstraints={parentRef}
      onDragEnd={onDrag}
      className={className}
    >
      {children}
    </motion.ul>
  );
};
