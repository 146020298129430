import * as React from "react";
import { TeamMember } from "../lib/types";
import TeamCard from "./teamcard";
import ResponsiveSlider from "./responsive-slider";

export interface TeamSliderProps {
  members: TeamMember[];
  className?: string;
}

const TeamSlider: React.FC<TeamSliderProps> = ({ members, className = "" }) => {
  return (
    <ResponsiveSlider className={className} duration={5000}>
      {members.map((member, index) => (
        <li key={index}>
          <TeamCard member={member} />
        </li>
      ))}
    </ResponsiveSlider>
  );
};

export default TeamSlider;
