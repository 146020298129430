import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { CustomerReview } from "../lib/types";

export interface CustomerReviewCardProps {
  review: CustomerReview;
}

const CustomerReviewCard: React.FC<CustomerReviewCardProps> = ({ review }) => (
  <div className="flex flex-col items-center rounded-md shadow-center bg-light-50 py-4 px-6 italic justify-between space-y-4">
    <div className={"space-y-4 flex-col items-center"}>
      <div className="flex items-center justify-center w-full">
        {review.image &&
          (review.image?.localFile.childImageSharp ? (
            <GatsbyImage
              image={getImage(review.image?.localFile)}
              alt={`photo de ${review.name}`}
              className="object-contain rounded-full m-0"
            />
          ) : (
            <img
              alt={`photo de ${review.name}`}
              src={review.image?.localFile.publicURL}
              className="object-contain rounded-full m-0"
            />
          ))}
      </div>
      <div className={"text-center text-sm md:text-base"}>
        « {review.review} »
      </div>
    </div>

    <div className="font-bold uppercase text-left w-full text-base md:text-lg">
      {review.name}
    </div>
  </div>
);

export default CustomerReviewCard;
