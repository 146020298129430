import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { ShareHolder } from "../lib/types";

export interface ShareHolderCardProps {
  shareholder: ShareHolder;
}

const ShareHolderCard: React.FC<ShareHolderCardProps> = ({ shareholder }) => (
  <div className="flex flex-col space-y-6 p-6 rounded-md shadow-center bg-light-50">
    {shareholder.logo.localFile.childImageSharp ? (
      <GatsbyImage
        image={getImage(shareholder.logo.localFile)}
        alt={`logo ${shareholder.name}`}
        className="h-20 object-contain"
      />
    ) : (
      <img
        alt={`logo ${shareholder.name}`}
        src={shareholder.logo.localFile.publicURL}
        className="h-20 object-contain"
      />
    )}

    <div className="space-y-4 h-80">
      <div className="font-bold uppercase text-base lg:text-lg">
        {shareholder.name}
      </div>

      <div className="text-sm lg:text-base h-60 overflow-y-scroll">
        {shareholder.description}
      </div>

      {shareholder.link && (
        <div>
          <a
            href={shareholder.link}
            target="_blank"
            className="text-secondary-100 font-bold text-sm hover:underline lg:text-base"
          >
            Je découvre {shareholder.name}
          </a>
        </div>
      )}
    </div>
  </div>
);

export default ShareHolderCard;
